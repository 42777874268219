import Head from "next/head";
import { ReactElement } from "react";
import MainMenu from "../components/MainMenu";

function ClientError(): ReactElement {

  return (
    <>
      <Head>
        <title>Page not found on That1Thing | Try Another!</title>
      </Head>
      <MainMenu />
      <div className="w-64 md:w-96 mx-auto pt-10">
        <div className="max-w-xs mx-auto mt-10">
          <img
            src={
              process.env.NEXT_PUBLIC_CDN + "/v1640021391/undraw_feeling_blue_4_b7q_938e9cee2f.svg"
            }
            alt="That1Thing could not find your request"
            className="w-full h-full"
          />
        </div>
        <h1 className="text-center font-semibold text-xl text-primary opacity-80 pt-10">
          {`Oh no! This page does not exist. Maybe try another?`}
        </h1>
      </div>
    </>
  );
}

export default ClientError;
